const LeaguePageReducer = (state, action) => {
  switch (action.type) {
    case 'LEAGUE_UPDATE':
      return action.data;
    default:
      return state;
  }
};

const LeagueNewsPageReducer = (state, action) => {
  switch (action.type) {
    case 'LEAGUE_NEWS_FETCHING':
      return {...state, isFetching: true};
    case 'LEAGUE_NEWS_UPDATE':
      const LeagueNewsPage = {...state};
      LeagueNewsPage.items = LeagueNewsPage.items.concat(action.data.items);
      LeagueNewsPage.nextUrl = action.data.nextUrl;
      LeagueNewsPage.isFetching = false;
      LeagueNewsPage.categoryFilter = action.data.categories;
      return LeagueNewsPage;
    case 'LEAGUE_NEWS_OVERWRITE':
      return action.data;
    case 'LEAGUE_NEWS_ERROR':
      return {
        ...state,
        error: action.data,
        isFetching: false,
        nextPage: null,
      };
    default:
      return state;
  }
};

const LeagueMatchesPageReducer = (state, action) => {
  switch (action.type) {
    case 'LEAGUE_MATCHES_FETCHING':
      return {...state, isFetching: true};
    case 'LEAGUE_MATCHES_OVERWRITE':
      return action.data;
    case 'LEAGUE_MATCHES_UPDATE':
      const LeagueMatchesPage = {...state};
      LeagueMatchesPage.isFetching = false;
      LeagueMatchesPage.items = LeagueMatchesPage.items.concat(action.data.items);
      LeagueMatchesPage.nextUrl = action.data.nextUrl;
      return LeagueMatchesPage;
    case 'LEAGUE_MATCHES_ERROR':
      return {
        ...state,
        error: action.data,
        isFetching: false,
        nextUrl: null,
      };
    default:
      return state;
  }
};

const initialStandingData = {
  total: {
    isFetching: false,
    data: {},
  },
  home: {
    isFetching: false,
    data: {},
  },
  away: {
    isFetching: false,
    data: {},
  },
  firstRound: {
    isFetching: false,
    data: {},
  },
  secondRound: {
    isFetching: false,
    data: {},
  },
  form: {
    isFetching: false,
    data: {},
  },
};

const LeagueStandingPageReducer = (state, action) => {
  switch (action.type) {
    case 'LEAGUE_STANDING_FETCHING':
      return {...state, [action.data.key]: {...state[action.data.key], isFetching: true}};
    case 'LEAGUE_STANDING_UPDATE':
      return {...state, [action.data.key]: {...state[action.data.key], isFetching: false, data: action.data.data}};
    default:
      return state;
  }
};

const LeagueRankingsPageReducer = (state, action) => {
  switch (action.type) {
    case 'LEAGUE_RANKINGS_FETCHING':
      return {...state, isFetching: true};
    case 'LEAGUE_RANKINGS_UPDATE':
      return {...state, data: action.data, isFetching: false};
    default:
      return state;
  }
};

const LeagueHistoryPageReducer = (state, action) => {
  switch (action.type) {
    case 'LEAGUE_HISTORY_FETCHING':
      return {...state, isFetching: true};
    case 'LEAGUE_HISTORY_UPDATE':
      return {...state, items: action.data, isFetching: false};
    default:
      return state;
  }
};

export {
  LeaguePageReducer,
  LeagueNewsPageReducer,
  LeagueMatchesPageReducer,
  LeagueStandingPageReducer,
  initialStandingData,
  LeagueRankingsPageReducer,
  LeagueHistoryPageReducer,
};
