const CupPageReducer = (state, action) => {
  switch (action.type) {
    case 'CUP_UPDATE':
      return action.data;
    default:
      return state;
  }
};

const CupNewsPageReducer = (state, action) => {
  switch (action.type) {
    case 'CUP_NEWS_FETCHING':
      return {...state, isFetching: true};
    case 'CUP_NEWS_UPDATE':
      const CupNewsPage = {...state};
      CupNewsPage.items = CupNewsPage.items.concat(action.data.items);
      CupNewsPage.nextUrl = action.data.nextUrl;
      CupNewsPage.isFetching = false;
      CupNewsPage.categoryFilter = action.data.categories;
      return CupNewsPage;
    case 'CUP_NEWS_OVERWRITE':
      return action.data;
    case 'CUP_NEWS_ERROR':
      return {...state, isFetching: false, nextUrl: null, error: action.data};
    default:
      return state;
  }
};

const CupOverviewPageReducer = (state, action) => {
  switch (action.type) {
    case 'CUP_OVERVIEW_FETCHING':
      return {...state, isFetching: true};
    case 'CUP_OVERVIEW_UPDATE':
      return {...state, isFetching: false, groups: action.data};
    case 'CUP_OVERVIEW_ROUND_MATCHES_FETCHING':
      return {
        ...state,
        details: {
          ...state.details,
          [action.data.key]: {show: true, items: {matches: [], standings: []}, error: null, isFetching: true},
        },
      };
    case 'CUP_OVERVIEW_ROUND_MATCHES_UPDATE':
      return {
        ...state,
        details: {
          ...state.details,
          [action.data.key]: {
            show: true,
            isFetching: false,
            items: action.data.items,
            error: null,
          },
        },
      };
    case 'CUP_OVERVIEW_ROUND_MATCHES_ERROR':
      return {
        ...state,
        details: {
          ...state.details,
          [action.data.key]: {
            show: true,
            items: {matches: [], standings: []},
            isFetching: false,
            error: action.data.error,
          },
        },
      };
    case 'CUP_GROUP_SHOW_UPDATE':
      return {
        ...state,
        details: {
          ...state.details,
          [action.data.groupSlug]: {
            ...state.details[action.data.groupSlug],
            show: action.data.show,
          },
        },
      };
    default:
      return state;
  }
};

const CupRankingsPageReducer = (state, action) => {
  switch (action.type) {
    case 'CUP_RANKINGS_FETCHING':
      return {...state, isFetching: true};
    case 'CUP_RANKINGS_UPDATE':
      return {...state, data: action.data, isFetching: false};
    default:
      return state;
  }
};

const CupHistoryPageReducer = (state, action) => {
  switch (action.type) {
    case 'CUP_HISTORY_FETCHING':
      return {...state, isFetching: true};
    case 'CUP_HISTORY_UPDATE':
      return {...state, items: action.data, isFetching: false};
    default:
      return state;
  }
};

export {CupPageReducer, CupNewsPageReducer, CupOverviewPageReducer, CupRankingsPageReducer, CupHistoryPageReducer};
