import {fetchNextApiCall, getCompetition, getCompetitionStats, getCompetitionHistory, api} from 'app/services/api';
import {changeHeaderEntity, changeHeaderFollowing, changeHeaderText} from 'app/actions/headerActions';
import {catchDataError, checkApiRedirect, RedirectException, parseLinkHeader} from 'app/helpers/apiHelpers';
import {
  checkCompetitionPathRedirect,
  getTodayAndYesterdayAsQuery,
  groupMatchesByKickoff,
} from 'app/helpers/actionsHelpers';
import {trySettingDefaultDistrict} from 'app/actions/sessionActions';
import {updateHistory} from 'app/actions/historyActions';
import {initialStandingData} from 'app/routes/league/LeaguePageReducer';
import {updateHttpError, updateHttpErrorSubroute} from 'app/components/error/ErrorHandlerActions';

const streamTypes = {
  news: 'news_published',
  'fupa-tv': 'playlist_published',
  gallery: 'gallery_published',
  transfer: 'profile_transfer',
  'coach-change': 'coach_changed',
  matchday: 'match_day_results',
  matchdelay: 'match_kickoff_changed',
  'final-score': 'match_result',
  ban: 'player_banned',
  topeleven: 'league_top_eleven',
  injury: 'player_injured',
  scorer: 'competition_scorers',
  matchevent: 'match_event',
};

const getLeagueStream = (leagueSlug, seasonSlug = 'current', categories) => {
  const streamURL = process.env.STREAM_URL;
  let url = `${streamURL}/competitions/${leagueSlug}/seasons/${seasonSlug}?limit=18`;
  if (Object.keys(streamTypes).includes(categories)) {
    const type = streamTypes[categories];
    url += `&type=${type}`;
  }
  return api.get(url);
};

const getCompetitionStandings = (competition, season, filter, group) => {
  const allowedTypes = ['home', 'away', 'firstRound', 'secondRound'];
  const filterType = filter && allowedTypes.includes(filter) ? filter : '';

  let request = `/standings?competition=${competition}`;
  request += season ? `&season=${season}` : '';
  request += filterType ? `&filter=${filterType}` : '';
  request += group ? `&group=${group}` : '';
  return api.get(request);
};

const getCompetitionForm = (competition, season = 'current', ts) => {
  let request = `/competitions/${competition}/seasons/${season}/standings/form`;
  request += ts ? `?ts=${ts}` : '';
  return api.get(request);
};

const getLeagueMatches = (leagueSlug, seasonSlug = 'current', pointer) => {
  const {today, yesterday} = getTodayAndYesterdayAsQuery();
  const query =
    seasonSlug === 'current'
      ? pointer === 'prev'
        ? `?to=${yesterday}&sort=desc`
        : `?from=${today}&fallback=true`
      : '?sort=desc';
  let request = `/competitions/${leagueSlug}/seasons/${seasonSlug}/matches${query}`;
  return api.get(request);
};

// LEAGUE_PAGE
function updateLeague(data) {
  return {
    type: 'LEAGUE_UPDATE',
    data,
  };
}

function setLeagueHeader(dispatch, league, seasonSlug) {
  const competitionSeasonName = seasonSlug ? `${league.name} ${league.season.name}` : league.name;
  const parent = {
    name: league.district.name,
    entity: 'district',
    slugs: {
      districtSlug: league.district.slug,
    },
  };

  dispatch(
    changeHeaderText({
      title: competitionSeasonName,
      parent,
    })
  );
}

function fetchLeagueMetaDataSSR(leagueSlug, seasonSlug) {
  return async function (dispatch, getState) {
    dispatch(changeHeaderFollowing(true));
    try {
      const response = await getCompetition(leagueSlug, seasonSlug);
      const league = response.data;
      checkApiRedirect(league.slug, leagueSlug, league.season, seasonSlug, getState, league.active);
      checkCompetitionPathRedirect(league?.category?.id, getState);
      dispatch(updateHistory('undefined', {store: 'LeaguePage', data: league}));
      dispatch(changeHeaderEntity({entity: 'competition', name: league.name, slug: league.slug, id: league.id}));
      setLeagueHeader(dispatch, league, seasonSlug);
      return trySettingDefaultDistrict(league, dispatch, getState);
    } catch (error) {
      if (error instanceof RedirectException) {
        throw error;
      } else {
        const errorData = catchDataError(error);
        dispatch(updateHttpError(errorData));
      }
    }
  };
}

function fetchLeagueMetaData(leagueSlug, seasonSlug, dispatchRedux, getState) {
  return function (dispatch, state) {
    dispatchRedux(changeHeaderFollowing(true));
    const leagueSeasonSlug = seasonSlug ? leagueSlug + '-' + seasonSlug : leagueSlug;
    if (Object.keys(state).length && state.slug) {
      let reduxDataSlug = state.slug;
      reduxDataSlug += seasonSlug && state.season?.slug ? `-${state.season.slug}` : '';
      if (leagueSeasonSlug === reduxDataSlug) {
        setLeagueHeader(dispatchRedux, state, seasonSlug);
        return;
      }
    }
    if (state?.status && state?.message) {
      return;
    }
    return getCompetition(leagueSlug, seasonSlug)
      .then(response => {
        const league = response.data;
        checkApiRedirect(league.slug, leagueSlug, league.season, seasonSlug, null, league.active);
        checkCompetitionPathRedirect(league?.category?.id);
        dispatch(updateLeague(league));
        dispatchRedux(changeHeaderEntity({entity: 'competition', name: league.name, slug: league.slug, id: league.id}));
        setLeagueHeader(dispatchRedux, league, seasonSlug);
        return trySettingDefaultDistrict(league, dispatchRedux, getState);
      })
      .catch(error => {
        if (error instanceof RedirectException) {
          throw error;
        } else {
          const errorData = catchDataError(error);
          dispatchRedux(updateHttpError(errorData));
        }
      });
  };
}

// LEAGUE_NEWS_PAGE
function updateNews(data) {
  return {
    type: 'LEAGUE_NEWS_UPDATE',
    data,
  };
}

function errorNews(data) {
  return {
    type: 'LEAGUE_NEWS_ERROR',
    data,
  };
}

function fetchingNews() {
  return {
    type: 'LEAGUE_NEWS_FETCHING',
  };
}

function overwriteNews(data) {
  return {
    type: 'LEAGUE_NEWS_OVERWRITE',
    data,
  };
}

function fetchLeagueNews(leagueSlug, seasonSlug, categories, reloading, dispatchRedux) {
  return function (dispatch, state) {
    if (!reloading && state.items.length) {
      return;
    }
    const {isFetching, nextUrl} = state;
    if (isFetching || nextUrl === null) {
      return;
    }
    const fetchFunc = nextUrl ? fetchNextApiCall(nextUrl) : getLeagueStream(leagueSlug, seasonSlug, categories);
    dispatch(fetchingNews());
    return fetchFunc
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(updateNews({items: response.data, nextUrl, categories}));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        nextUrl ? dispatch(errorNews(errorData)) : dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

function fetchLeagueNewsSSR(leagueSlug, seasonSlug, categories) {
  return function (dispatch) {
    dispatch(fetchingNews());
    return getLeagueStream(leagueSlug, seasonSlug, categories)
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(
          updateHistory('undefined', {
            store: 'LeagueNewsPage',
            data: {items: response.data, nextUrl, categoryFilter: categories, error: null, isFetching: false},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

// LEAGUE_MATCHES_PAGE
function updateMatches(data) {
  return {
    type: 'LEAGUE_MATCHES_UPDATE',
    data,
  };
}

function errorMatches(data) {
  return {
    type: 'LEAGUE_MATCHES_ERROR',
    data,
  };
}

function fetchingMatches() {
  return {
    type: 'LEAGUE_MATCHES_FETCHING',
  };
}

function overwriteMatches(data) {
  return {
    type: 'LEAGUE_MATCHES_OVERWRITE',
    data,
  };
}

function fetchLeagueMatchesSSR(leagueSlug, seasonSlug, pointer) {
  return function (dispatch) {
    return getLeagueMatches(leagueSlug, seasonSlug, pointer)
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        const items = groupMatchesByKickoff(response.data, pointer, seasonSlug);
        dispatch(
          updateHistory('undefined', {
            store: 'LeagueMatchesPage',
            data: {items, nextUrl, isFetching: false, error: null},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

function fetchLeagueMatches(leagueSlug, seasonSlug, pointer, reloading, dispatchRedux) {
  return function (dispatch, state) {
    if (!reloading && state.items.length) {
      return;
    }

    const {isFetching, nextUrl} = state;
    if (isFetching || nextUrl === null) {
      return;
    }
    const fetchFunc = nextUrl ? fetchNextApiCall(nextUrl) : getLeagueMatches(leagueSlug, seasonSlug, pointer);

    dispatch(fetchingMatches());
    return fetchFunc
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        const items = groupMatchesByKickoff(response.data, pointer, seasonSlug);
        dispatch(updateMatches({items, nextUrl}));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        nextUrl ? dispatch(errorMatches(errorData)) : dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

// LEAGUE_STANDING_PAGE
function updateStanding(data) {
  return {
    type: 'LEAGUE_STANDING_UPDATE',
    data,
  };
}

function fetchingStanding(data) {
  return {
    type: 'LEAGUE_STANDING_FETCHING',
    data,
  };
}

function fetchLeagueStandingsSSR(competition, season, filter) {
  return function (dispatch) {
    let key = filter ?? 'total';
    const fetchEP =
      key === 'form' ? getCompetitionForm(competition, season) : getCompetitionStandings(competition, season, filter);

    return fetchEP
      .then(response => {
        dispatch(
          updateHistory('undefined', {
            store: 'LeagueStandingPage',
            data: {...initialStandingData, [key]: {data: response.data, isFetching: false}},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

function fetchLeagueStandings(competition, season, filter, standingFormUpdatedAt, dispatchRedux) {
  return function (dispatch, state) {
    let key = filter ?? 'total';
    if (key in state && Object.keys(state[key].data).length) {
      return;
    }

    dispatch(fetchingStanding({key}));
    const fetchEP =
      key === 'form'
        ? getCompetitionForm(competition, season, standingFormUpdatedAt)
        : getCompetitionStandings(competition, season, filter);
    return fetchEP
      .then(response => {
        dispatch(updateStanding({key, data: response.data}));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

// LEAGUE_RANKINGS_PAGE
function updateRankings(data) {
  return {
    type: 'LEAGUE_RANKINGS_UPDATE',
    data,
  };
}

function fetchingRankings() {
  return {
    type: 'LEAGUE_RANKINGS_FETCHING',
  };
}

function fetchLeagueRankingsSSR(competitionSlug, seasonSlug) {
  return function (dispatch) {
    return getCompetitionStats(competitionSlug, seasonSlug)
      .then(response => {
        dispatch(
          updateHistory('undefined', {
            store: 'LeagueRankingsPage',
            data: {data: response.data, isFetching: false},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

function fetchLeagueRankings(competitionSlug, seasonSlug, dispatchRedux) {
  return function (dispatch, state) {
    if (Object.keys(state.data).length) {
      return;
    }
    dispatch(fetchingRankings());
    return getCompetitionStats(competitionSlug, seasonSlug)
      .then(response => {
        dispatch(updateRankings(response.data));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

// LEAGUE_HISTORY_PAGE
function updateLeagueHistory(data) {
  return {
    type: 'LEAGUE_HISTORY_UPDATE',
    data,
  };
}

function fetchingHistory() {
  return {
    type: 'LEAGUE_HISTORY_FETCHING',
  };
}

function fetchLeagueHistorySSR(slug) {
  return function (dispatch) {
    return getCompetitionHistory(slug)
      .then(response => {
        dispatch(
          updateHistory('undefined', {
            store: 'LeagueHistoryPage',
            data: {items: response.data, isFetching: false},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

function fetchLeagueHistory(slug, dispatchRedux) {
  return function (dispatch, state) {
    if (state.items.length) {
      return;
    }
    dispatch(fetchingHistory());
    return getCompetitionHistory(slug)
      .then(response => {
        dispatch(updateLeagueHistory(response.data));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

export {
  fetchLeagueMetaData,
  fetchLeagueMetaDataSSR,
  fetchLeagueNews,
  fetchLeagueNewsSSR,
  fetchLeagueMatches,
  fetchLeagueMatchesSSR,
  fetchLeagueStandings,
  fetchLeagueStandingsSSR,
  fetchLeagueRankings,
  fetchLeagueRankingsSSR,
  fetchLeagueHistory,
  fetchLeagueHistorySSR,
  overwriteNews,
  overwriteMatches,
};
